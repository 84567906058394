import React from "react";
import App from "./src/App";

export default function Root(props) {
    return (
        <div>
            <App />
        </div>
    );
}
