import React from "react";
import { Navbar } from "./page/navbar";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { fetchAdminAPIWithPermission } from "@aim-mf/api";
import { navigateToUrl } from "single-spa";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const initialState = {
    loadingCurrentUser: true,
    currentUser: {}
};
function App() {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    console.log(state);
    const { currentUser, loadingCurrentUser } = state;

    React.useEffect(() => {
        //console.log(loadingCurrentUser)
        if (loadingCurrentUser) {
            dispatch({ type: "loadCurrentUser" });
            dispatch({
                type: "loadCurrentUserInfo",
                data: {
                    ...JSON.parse(sessionStorage.getItem("CurrentUser")),
                    ...JSON.parse(sessionStorage.getItem("MyProfile"))
                }
            });
        }
    }, [loadingCurrentUser]);

    const handleLogOut = React.useCallback(() => {
        const userSubscription = fetchAdminAPIWithPermission(
            "auth/logout"
        ).subscribe(
            results => {
                sessionStorage.removeItem("CurrentUser");
                sessionStorage.removeItem("MyProfile");
                sessionStorage.removeItem("jwt");
                sessionStorage.removeItem("nav");
                navigateToUrl("/login/");
            },
            err => {
                AuthCheckAndAutofix(err, () => {
                    handleLogOut();
                });
            }
        );

        return () => userSubscription.unsubscribe();
    });

    return (
        <div>
            <Navbar currentUser={currentUser} handleLogOut={handleLogOut} />
        </div>
    );
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case "loadCurrentUser":
            return {
                ...state,
                loadingCurrentUser: true
            };
        case "loadCurrentUserInfo":
            return {
                ...state,
                currentUser: action.data,
                loadingCurrentUser: false
            };
        default:
            throw Error(`Unknown action type '${action.type}'`);
    }
}

export default App;
