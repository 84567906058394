import React from "react";
import {
    mapping,
    SmallAvatar,
    navbarStyle,
    ButtonSolid
} from "@aim-mf/styleguide";
import { handleLogout } from "@aim-mf/api";
import { navigateToUrl } from "single-spa";
class UserBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        console.log(this.props.currentUser);
        return (
            <div
                style={{
                    ...navbarStyle.userBlockStyle,
                    width: "fit-content",
                    paddingRight: "15px"
                }}
            >
                {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*/}
                <button style={navbarStyle.userBlockIconButton}>
                    <span
                        className="k-icon k-i-notification k-i-bell"
                        style={navbarStyle.userBlockIconStyle}
                    />
                </button>
                <button style={navbarStyle.userBlockIconButton}>
                    <span
                        className="k-icon k-i-gear"
                        style={navbarStyle.userBlockIconStyle}
                    />
                </button>
                <button
                    style={navbarStyle.userBlockIconButton}
                    onClick={() => {
                        navigateToUrl("/user-profile");
                    }}
                >
                    >
                    {this.props.currentUser.display_name && (
                        <div style={navbarStyle.userBlockAvatarStyle}>
                            <SmallAvatar
                                initial={this.props.currentUser.display_name
                                    .split(" ")
                                    .map(a => {
                                        return a[0].toUpperCase();
                                    })
                                    .join("")}
                            />
                            <div
                                style={{
                                    ...mapping["paragraph/default/lightleft"],
                                    alignSelf: "center"
                                }}
                            >
                                {this.props.currentUser.display_name}
                            </div>
                        </div>
                    )}
                    <span
                        className="k-icon k-i-arrow-60-down"
                        style={navbarStyle.userBlockIconStyle}
                    />
                </button>
                <div style={{ display: "inline-flex" }}>
                    <ButtonSolid
                        name={"Log out"}
                        clickEvent={this.props.handleLogOut}
                    />
                </div>
            </div>
        );
    }
}

export { UserBlock };
