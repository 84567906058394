import React from "react";
import { navbarStyle, Tab } from "@aim-mf/styleguide";
import { UserBlock } from "./userblock";
import { navigateToUrl } from "single-spa";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        console.log(this.props.currentUser);

        const OnTabChange = label => {
            switch (label) {
                case "Dashboard":
                    navigateToUrl("/dashboard");
                    break;
                case "Risk Management":
                    navigateToUrl("/risk-management");
                    break;
                case "My Task":
                    navigateToUrl("/task-management");
                    break;
                case "Admin":
                    navigateToUrl("/admin");
                    break;
                default:
                    alert("label received does not match url");
            }
            console.log("nav to " + label);
        };

        let labelURLMapping = {};
        if (this.props.currentUser.access_level < 3) {
            console.log(this.props.currentUser.access_level);
            labelURLMapping = {
                dashboard: "Dashboard",
                "task-management": "My Task",
                "risk-management": "Risk Management",
                admin: "Admin"
            };
        } else {
            console.log(this.props.currentUser.access_level);
            labelURLMapping = {
                dashboard: "Dashboard",
                "task-management": "My Task",
                "risk-management": "Risk Management"
            };
        }

        let CurrentLabel = document.location.pathname.split("/");
        CurrentLabel = labelURLMapping[CurrentLabel[1]];
        // console.log(CurrentLabel)
        return (
            <div style={navbarStyle.backgroundStyle}>
                <Tab
                    OnTabChange={OnTabChange}
                    tabWidth={"9rem"}
                    height={"100%"}
                    labels={Object.values(labelURLMapping)}
                    value={CurrentLabel}
                />
                <UserBlock
                    currentUser={this.props.currentUser}
                    handleLogOut={this.props.handleLogOut}
                />
            </div>
        );
    }
}

export { Navbar };
